var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('br'), _c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "mt-4 mb-4 container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "to": {
        name: 'artikel'
      }
    }
  }, [_vm._v(" Artikel ")]), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.artikelDetail.title) + " ")])], 1)], 1), _c('center', [_c('div', {
    staticClass: "blog-detail-wrapper col-sm-10"
  }, [_c('span', [_vm._v("Diposting pada " + _vm._s(_vm.posting(_vm.artikelDetail.created_at)))]), _c('h1', {
    staticClass: "mt-1 mb-1"
  }, [_vm._v(_vm._s(_vm.artikelDetail.title))]), _c('b-img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": _vm.apiFile + _vm.artikelDetail.thumbnail,
      "img-top": "",
      "img-alt": "Blog Detail Pic"
    }
  }), _c('br'), _vm.artikelDetail.category ? _c('b-badge', {
    staticClass: "mb-2 mt-1",
    attrs: {
      "variant": _vm.artikelDetail.category.color
    }
  }, [_vm._v(" " + _vm._s(_vm.artikelDetail.category.name) + " ")]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-11"
  }, [_c('div', {
    staticClass: "blog-content text-left mt-1",
    domProps: {
      "innerHTML": _vm._s(_vm.artikelDetail.content)
    }
  })]), _c('div', {
    staticClass: "col-sm"
  }, [_c('b-link', {
    staticClass: "icon-mobile",
    attrs: {
      "href": "https://www.facebook.com/sharer/sharer.php?u=http://elp-dev.my.id/artikel/detail/".concat(_vm.artikelDetail.slug),
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "text-dark ml-1 mr-1 mt-2 mb-2",
    attrs: {
      "icon": "FacebookIcon",
      "size": "18"
    }
  })], 1), _c('b-link', {
    staticClass: "icon-mobile",
    attrs: {
      "href": "https://twitter.com/intent/tweet?url=http://elp-dev.my.id/artikel/detail/".concat(_vm.artikelDetail.slug),
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "text-dark ml-1 mr-1 mt-2 mb-2",
    attrs: {
      "icon": "TwitterIcon",
      "size": "18"
    }
  })], 1), _c('b-link', {
    staticClass: "icon-mobile",
    attrs: {
      "href": "https://www.linkedin.com/sharing/share-offsite/?url=http://elp-dev.my.id/artikel/detail/".concat(_vm.artikelDetail.slug),
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    staticClass: "text-dark ml-1 mr-1 mt-2 mb-2",
    attrs: {
      "icon": "LinkedinIcon",
      "size": "18"
    }
  })], 1), _c('b-link', {
    staticClass: "icon-mobile",
    attrs: {
      "href": "whatsapp://send?text=http://elp-dev.my.id/artikel/detail/".concat(_vm.artikelDetail.slug),
      "target": "_blank",
      "data-action": "share/whatsapp/share"
    }
  }, [_c('feather-icon', {
    staticClass: "text-dark ml-1 mr-1 mt-2 mb-2",
    attrs: {
      "icon": "PhoneIcon",
      "size": "18"
    }
  })], 1)], 1)])], 1)])], 1), _c('br'), _c('br'), _c('br'), _c('footer-home')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }