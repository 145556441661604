<template>
  <div class="">
    <b-overlay :show="loading">
      <br />
      <b-navbar
        class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
      >
        <slot name="navbar">
          <profile-header />
        </slot>
      </b-navbar>
      <div class="mt-4 mb-4 container" style="width: 90%;">
        <b-breadcrumb class="breadcrumb-chevron">
          <b-breadcrumb-item :to="{ name: 'artikel' }">
            Artikel
          </b-breadcrumb-item>
          <b-breadcrumb-item active>
            {{ artikelDetail.title }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <center>
        <div class="blog-detail-wrapper col-sm-10">
          <span>Diposting pada {{ posting(artikelDetail.created_at) }}</span>
          <h1 class="mt-1 mb-1">{{ artikelDetail.title }}</h1>
          <b-img
            :src="apiFile + artikelDetail.thumbnail"
            img-top
            img-alt="Blog Detail Pic"
            class=""
            style="width: 100%;"
          />
          <br />
          <b-badge
            v-if="artikelDetail.category"
            :variant="artikelDetail.category.color"
            class="mb-2 mt-1"
          >
            {{ artikelDetail.category.name }}
          </b-badge>
          <div class="row">
            <div class="col-lg-11">
              <div
                class="blog-content text-left mt-1"
                v-html="artikelDetail.content"
              ></div>
            </div>
            <div class="col-sm">
              <b-link
                class="icon-mobile"
                :href="`https://www.facebook.com/sharer/sharer.php?u=http://elp-dev.my.id/artikel/detail/${artikelDetail.slug}`"
                target="_blank"
              >
                <feather-icon
                  icon="FacebookIcon"
                  size="18"
                  class="text-dark ml-1 mr-1 mt-2 mb-2"
                />
              </b-link>

              <b-link
                class="icon-mobile"
                :href="`https://twitter.com/intent/tweet?url=http://elp-dev.my.id/artikel/detail/${artikelDetail.slug}`"
                target="_blank"
              >
                <feather-icon
                  icon="TwitterIcon"
                  size="18"
                  class="text-dark ml-1 mr-1 mt-2 mb-2"
                />
              </b-link>

              <b-link
                class="icon-mobile"
                :href="`https://www.linkedin.com/sharing/share-offsite/?url=http://elp-dev.my.id/artikel/detail/${artikelDetail.slug}`"
                target="_blank"
              >
                <feather-icon
                  icon="LinkedinIcon"
                  size="18"
                  class="text-dark ml-1 mr-1 mt-2 mb-2"
                />
              </b-link>

              <b-link
                class="icon-mobile"
                :href="`whatsapp://send?text=http://elp-dev.my.id/artikel/detail/${artikelDetail.slug}`"
                target="_blank"
                data-action="share/whatsapp/share"
              >
                <feather-icon
                  icon="PhoneIcon"
                  size="18"
                  class="text-dark ml-1 mr-1 mt-2 mb-2"
                />
              </b-link>
            </div>
          </div>
          <!--/ blogs -->
        </div>
      </center>
    </b-overlay>
    <br />
    <br />
    <br />
    <footer-home />
    <!--/ content -->
  </div>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BOverlay,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import ProfileHeader from './components/Navbar.vue'
import FooterHome from './components/Footer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BOverlay,
    BBreadcrumb,
    BBreadcrumbItem,
    ContentWithSidebar,
    ProfileHeader,
    FooterHome,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      artikelDetail: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      slug: this.$route.params.slug,
      loading: false,
    }
  },
  computed: {
    apiFile() {
      return 'http://file.elp-dev.my.id/'
    },
  },
  created() {
    this.getData()
  },
  methods: {
    kFormatter,
    posting(tanggal) {
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ]
      let date = new Date(tanggal)
      let day = date.getDate()
      let month = date.getMonth()
      let year = date.getFullYear()
      return day + ' ' + monthNames[month] + ' ' + year
    },
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    getData() {
      this.loading = true
      let slug = this.slug
      this.$store
        .dispatch('artikel/publicDetail', slug)
        .then((res) => {
          this.loading = false
          this.artikelDetail = res.data
        })
        .catch((err) => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error `,
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

@media (max-width: 480px) {
  .icon-mobile {
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
